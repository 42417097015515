import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "game",
        component: () => import("../views/Game"),
    },
    {
        path: "/my",
        name: "my",
        component: () => import("../views/user/My"),

    },
     //帮助
    {
        path: "/helper", name: "helperLink",component: () => import("../views/myHelper/Helper"),
    },
    {
        path:"/myHelper/questions/questions",name:"questionsLink", component: () => import("../views/myHelper/questions/Questions"),
    },
    { 
        path: "/myHelper/feedback/feedback", name:'feedbackLink', component: () => import("../views/myHelper/feedback/Feedback") 

    },
    { 
        path: "/myHelper/chat/robot", name:'robotLink', component: () => import("@/views/myHelper/chat/Robot")
    },
    {  
        path: "/myHelper/feedback/myservice", name:'feedbackMyServiceLink', component: () => import("@/views/myHelper/feedback/MyService") 

    },
    {  //合服
        path: "/myHelper/questions/detail", name:'questionsDetailLink', component: () => import("@/views/myHelper/questions/Detail") 
    },
    { 
        path: "/myHelper/feedback/detail/:id", name:'feedbackDetailLink', component: () => import("@/views/myHelper/feedback/Detail") 
    },

    {
        path: "/user/bindemail",
        name: "bindEmail",
        component: () => import("../views/user/BindEmail"),
    },
    {
        path: "/gameplay/",
        name: "gameplay",
        component: () => import("@/views/GamePlay"),
    },
    {
        path: "/user/password",
        name: "password",
        component: () => import("../views/user/Password"),
    },
    {
        path: "/user/bindmobile",
        name: "bindMobile",
        component: () => import("../views/user/BindMobile"),
    },
    {
        path: "/user/AccountDestroy",
        name: "accountdestroyLink",
        component: () => import("../views/user/AccountDestroy"),
    },
    { 
        path: "/user/myinfo", name:'myInfoLink', component: () => import("@/views/user/MyInfo")
     },
    // 游戏点数
    { 
        path: "/gamePoints/points", name:'points', component: () => import("@/views/gamePoints/Points") 
    },
    { 
        path: "/gamePoints/paypassword", name:'payPassword', component: () => import("@/views/gamePoints/PayPassword") 
    },
    { 
        path: "/gamePoints/payforgot", name:'payForgot', component: () => import("@/views/gamePoints/PayForgot") 
    },
    { 
        path: "/gamePoints/install", name:'install', component: () => import("@/views/gamePoints/install") 
    },

    { 
        path: '/payCenter/pay', name:'payLink', component: () => import("@/views/payCenter/Pay") 
    },
    { 
        path: '/payCenter/payReturn', name:'payReturnLink', component: () => import("@/views/payCenter/PayReturn") 
    },
    { 
        path: '/payCenter/wechatNative', name:'wechatNativeLink', component: () => import("@/views/payCenter/WechatNative") 
    },
    //落地页
    {
        path: '/ldy/squeezePlay', name:'squeezePlayLink', component: () => import("@/views/ldy/squeezePlay") 
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
    // console.log("router to")
    console.log(to);
     // console.log("router from")
    // console.log(from.fullPath);
    
    window.backfullPath = from.fullPath;
    next();
});
export default router;
